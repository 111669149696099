// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'D:/dev/workspace/light-knit-front/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/Loading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index.js'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/about",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about__index' */'@/pages/about/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/brand",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__brand__index' */'@/pages/brand/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/bussiness",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bussiness__index' */'@/pages/bussiness/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/contact",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contact__index' */'@/pages/contact/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/home",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/innovate",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__innovate__index' */'@/pages/innovate/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/message",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__message__index' */'@/pages/message/index.js'), loading: LoadingComponent})
      },
      {
        "path": "/product",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product__index' */'@/pages/product/index.js'), loading: LoadingComponent})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
