import { useState } from 'react';

export default function State() {
  const [loading, setLoading] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [listAllDict, setListAllDict] = useState([]);
  return {
    loading,
    setLoading,
    menuList,
    setMenuList,
    listAllDict,
    setListAllDict,
  };
}
