import './index.less';

const RotaButton = ({ text = '01', onClick }) => {
  return (
    <div className="rota-button" style={{ width: 110, height: 110 }}>
      <svg
        width={110}
        height={110}
        viewBox="0 0 140 140"
        className='rota-button-svg'
      >
        <defs>
          <path id="circle" d="M70,20 A50,50 0 1,1 70,120 A50,50 0 1,1 70,20" />
        </defs>
        <text>
          <textPath href="#circle" fill="#fff" fontSize={12}>
            PUT&nbsp;
            DOWN&nbsp;THE&nbsp;&nbsp;CLOTH&nbsp;&nbsp;AND&nbsp;&nbsp;LOOK&nbsp;AT&nbsp;THE&nbsp;LIGHT
          </textPath>
        </text>
      </svg>
      <div className="rota-button-text">{text}</div>
    </div>
  );
};

export default RotaButton;
