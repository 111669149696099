import { useState } from 'react';

let defaultData = [
  { label: '首页', path: '/home' },
  { label: '产品应用', path: '/product' },
  { label: '解决方案', path: '/solution' },
  { label: '客户案例', path: '/case' },
  { label: '组件市场', path: '/componentMarket' },
  { label: '模板市场', path: '/template' },
  { label: '帮助中心', path: '/help' },
  { label: '咨讯中心', path: '/message' },
];
export default function Demo() {
  const [menuSaveData, setMenuSaveData] = useState(defaultData);

  return { menuSaveData, setMenuSaveData };
}
