import RotaButton from '../RotaButton';
import './index.less';

const Loading = ({ loading }) => {
  const loadingContent = <img src={require('../../assets/logo-active.png')} />;

  return (
    <div
      className="loading-main"
      style={{ display: loading ? 'flex' : 'none' }}
    >
      <RotaButton text={loadingContent} />
    </div>
  );
};

export default Loading;
